/**
 * Register resource for async load with require-bundle
 */
 (function(){

    'use strict';

    // Bail if require bundle or server variables not loaded
    if ( ! RequireBundle || ! fluidthemeSettings ) return;

    var settings = fluidthemeSettings,
        ver = fluidthemeSettings.assetsVersion;

    // Helper Libraries
    RequireBundle.register( 'animate-helper', [ settings.jsLibPath + 'animate-helper' + ver + '.js' ], 'body' );
    RequireBundle.register( 'polyfill-inert', [ settings.jsLibPath + 'inert' + ver + '.js' ], 'body' );
    RequireBundle.register( 'atomicjs', [ settings.jsLibPath + 'atomic' + ver + '.js' ] );
    RequireBundle.register( 'button-states', [ settings.jsLibPath + 'button-states' + ver + '.js' ], '[data-auto-loading-state]', function(){ ButtonStates.init(); } );
    RequireBundle.register( 'collapsible-block', [ settings.jsLibPath + 'collapsible-block' + ver + '.js', settings.cssPath + 'collapsible-block' + ver + '.css' ], '[data-collapsible],.schema-faq-section', function(){ CollapsibleBlock.init( settings.collapsibleBlocks ); } );
    RequireBundle.register( 'fluid-slider', [ settings.jsLibPath + 'fluid-slider' + ver + '.js', settings.cssPath + 'fluid-slider' + ver + '.css' ], '.slider-wrapper', function(){ FluidSlider.init( settings.fluidSlider ); } );
    RequireBundle.register( 'flyout-block', [ settings.jsLibPath + 'flyout-block' + ver + '.js', settings.cssPath + 'flyout-block' + ver + '.css' ], '[data-flyout]', function(){ FlyoutBlock.init( settings.flyoutBlock ); } );
    RequireBundle.register( 'form-serialize', [ settings.jsLibPath + 'form-serialize.js' ] );
    RequireBundle.register( 'hammerjs', [ settings.jsLibPath + 'hammer' + ver + '.js' ] );
    RequireBundle.register( 'sticky-states', [ settings.jsLibPath + 'sticky-states' + ver + '.js' ], '[data-sticky-states]', function(){ StickyStates.init( settings.stickyStates ); } );

    // Theme Style bundles
    RequireBundle.register( 'post-styles', [ settings.cssPath + 'post' + ver + '.css' ], '.blog-posts__list,.post-excerpt,.single-post' );
    RequireBundle.register( 'navigation-styles', [ settings.cssPath + 'navigation' + ver + '.css' ], '.pagination,.woocommerce-pagination,.nav-links,.page-links' );
    RequireBundle.register( 'block-yoast-faq', [ settings.cssPath + 'block-yoast-faq' + ver + '.css' ], '.schema-faq-section' );

})();
